import React, { createContext, useState, useEffect } from 'react';

// Create the context
const CartContext = createContext();

// Create the provider component
const CartProvider = ({ children }) => {
  const [showSide, setShowSide] = useState(false);
  const [cart, setCart] = useState(() => {
    // Get the cart from local storage if it exists
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  });

  // Save the cart to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  // Add item to the cart if it doesn't already exist
  const addItem = (item) => {
    setCart((prevCart) => {
      // Check if the item already exists in the cart
      const itemExists = prevCart.some(cartItem => cartItem.id === item.id);
      if (itemExists) {
        // If item exists, return the current cart state
        return prevCart;
      } else {
        // If item doesn't exist, add it to the cart with a quantity of 1
        return [...prevCart, { ...item, quantity: 1 }];
      }
    });
    setShowSide(true);
  };

  // Remove item from the cart
  const removeItem = (itemId) => {
    setCart((prevCart) => prevCart.filter(item => item.id !== itemId));
  };

  // Increase the quantity of an item
  const increaseQuantity = (index) => {
    setCart((prevCart) => {
      const updatedCart = [...prevCart];
      updatedCart[index].quantity += 1;
      return updatedCart;
    });
  };

  // Decrease the quantity of an item
  const decreaseQuantity = (index) => {
    setCart((prevCart) => {
      const updatedCart = [...prevCart];
      if (updatedCart[index].quantity > 1) {
        updatedCart[index].quantity -= 1;
      } else {
        updatedCart.splice(index, 1);
      }
      return updatedCart;
    });
  };

  // Clear the cart
  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider value={{ cart, addItem, removeItem, increaseQuantity, decreaseQuantity, clearCart, showSide, setShowSide }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };

import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap'; 
import image from "../../assets/imgs/success.svg"

const Success = ({values}) => {
  return (
    <Container fluid className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
   <Row  >
        <Col>

          <img
            src={image}
            alt="Success"
            className='w-100'   
          />
          <h1 className="my-4 text-center">Success!</h1>
          <p className="mb-4 text-center">Your operation was completed successfully.</p>
             <div className='d-flex align-items-center justify-content-center'> 
              <Button href="/" className='  w-100 pt-2  ' variant='success' >Go to Home</Button>
          </div>
        
        </Col>
      </Row>
    </Container>
  );
};

export default Success;

import React, { createContext, useState, useEffect } from 'react';
import { getSubdomain } from './getSubdomain';

export const SubdomainContext = createContext();

export const SubdomainProvider = ({ children }) => {
  const [subdomain, setSubdomain] = useState('');
  useEffect(() => {
    const fetchSubdomain = async () => {
      const currentSubdomain = getSubdomain(window.location.hostname);
      setSubdomain(currentSubdomain);
    };
    fetchSubdomain();
  }, []);

  return (
    <SubdomainContext.Provider value={{ subdomain }}>
      {children}
    </SubdomainContext.Provider>
  );
};
import "./App.css";
import React, { useContext, useEffect, useState } from "react";
import { SubdomainContext } from "./utils/SubdomainContext";
import data from "./example_response.json";
import data2 from "./example_response2.json";
import data3 from "./example_response3.json";
import data4 from "./example_response4.json";
import data5 from "./example_response5.json";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getPathSegments } from "./utils/getPathSegments";
import Loading from "./themes/deriwin/components/loading/Loading";
import Success from "./themes/deriwin/components/success/Success"; 
import AOS from "aos";
import "aos/dist/aos.css";
function App() {
  const [theme, setTheme] = useState(null);
  const [sections, setSections] = useState([]);
  const { subdomain } = useContext(SubdomainContext);
  const navigate = useNavigate();
  useEffect(() => {
    setTheme(null);
    setSections([])
    AOS.init();
    const pathSegments = getPathSegments(window.location.pathname);
    console.log(subdomain, pathSegments);
    // Fetch data from backend
    fetch(`http://127.0.0.1:8000/${subdomain}`)
      .then((response) => response.json())
      .then(() => {
        setTheme(data.name);
        document.title = data.name;
        setSections(data.sections);
      })
      .catch(() => {
        if (pathSegments[0] === "shop") {
          setTheme(data3.name);
          document.title = data3.name;
          setSections(data3.sections);
        } else if (pathSegments[0] === "cart") {
          setTheme(data2.name);
          document.title = data2.name;

          setSections(data2.sections);
        } else if (pathSegments[0] === "place-order") {
          setTheme(data4.name);
          document.title = data4.name;

          setSections(data4.sections);
        } else if (pathSegments[0] === 'product') {
          setTheme(data5.name);
          document.title = data5.name;

          setSections(data5.sections);
        }
        else {
          setTheme(data.name);
          document.title = data.name;

          setSections(data.sections);
        }
      });
  }, [subdomain, navigate]);

  if (!theme) {
    return <div><Loading/></div>;
  }

  return (
    <div>
      {sections.map((section, index) => {
        const SectionComponent = React.lazy(() =>
          import(`./themes/${theme}/sections/${section.name}`)
        );
        return (
          <React.Suspense fallback={<div></div>} key={index}>
            <SectionComponent values={section.values} />
          </React.Suspense>
        );
      })}
        <Routes> 
        <Route path="/success-message" element={<Success />} />  
      </Routes>
    </div>
  );
}

export default App;

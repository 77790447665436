// components/Loading.js
import React from 'react';
import './loading.css'
const Loading = () => {
  return (
    <div className="loading-container">
      <div className="spinner"></div>  
 
    </div>
  );
};

export default Loading;
